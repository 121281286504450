import React from "react";

const Intro = () => {
  return (
    <section className="PgT-intro" id="home">
      <div className="container text-center">
        <h1>Testimonials</h1>
        <p>Here is what famous Amazon sellers think about us</p>
      </div>
    </section>
  );
};

export default Intro;
